
._user-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-right: 8px;
    &-eth {
        background-image: url('../images/icon-eth.svg');
        width: 16px;
        margin-left: 5px;
        height: 20px;
        vertical-align: -webkit-baseline-middle;
    }
    &-polygon {
        background-image: url('../images/icon-polygon.svg');
        width: 16px;
        margin-left: 5px;
        height: 20px;
        vertical-align: -webkit-baseline-middle;
    }
}

.profile-section {
    margin-top: 60px;
    // background-image: url('../mockup/monkey.png');
    background-attachment: fixed;
    background-position: center 60px;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 2em;
    position: relative;
    z-index: 1;
    &._default {
        background-color: #5d5c5c;
    }
    &:after {
        content:'';
        background-color: rgba(0, 0, 0, 0.65);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top:0;
        z-index: -1;
    }
    ._icon {
        display: inline-block;
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        background-size: 100%;
        margin-right: 8px;

        &-share {
            background-image: url('../images/icon-share.svg');
        }

        &-fav {
            background-image: url('../images/icon-fav.svg');
        }

        &-tweet {
            background-image: url('../images/icon-tweet.svg');
        }

        &-verify {
            background-image: url('../images/icon-verify.svg');
            vertical-align: middle;
            width: 25px;
            height: 30px;
        }
        &-eth {
            background-image: url('../images/icon-eth.svg');
            width: 16px;
            margin-left: 5px;
            height: 30px;
        }
        &-polygon {
            background-image: url('../images/icon-polygon.svg');
            width: 16px;
            margin-left: 5px;
            height: 30px;
            vertical-align: -webkit-baseline-middle;
        }
    }

    .collection-profile {
        color: var(--alternative-text-color);
        padding: 2em;

        .description {
            margin-top: 1.5em;
            font-size: var(--font-medium-x);
        }

        h2 {
            text-transform: uppercase;
            font-size: var(--font-large-x);
            color: var(--primary-text-color);
        }

        .hash-profile {
            border: 1px solid var(--alternative-text-color);
            color: var(--primary-text-color);
            border-radius: 20px;
            padding: .1em .5em;
            display: inline-block;
            cursor: pointer;
            font-size: 1.4em;
            line-height: 1.4;
            svg {
                font-size: .78em;
            }
        }
    }
}

.card-box {
    padding: 1em;
    padding-top: 2em;
    // padding-bottom: 2em;
    border-radius: var(--s-radius);
    background: url('../images/bg-gradient.svg');
    background-size: auto;
    background-repeat: no-repeat;
    font-size: var(--font-small);
    text-align: center;
    position: relative;
    color: var(--primary-text-color);
    font-size: var(--font-medium-x);
    text-transform: uppercase;
    margin-bottom: 2em;
    position: relative;

    .amount-nft {
        position: absolute;
        top: 50px;
        left: 50px;
        color: #fff;
        font-size: 1.2em;
        background: rgba(0, 0, 0, 0.55);
        opacity: 0.5;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        padding: 0.2em 0.5em;
    }
    .icon-play{
        position: absolute;
        font-size: 2.5rem;
        color: #000;
        right: 12%;
        bottom: 20%;
    }
    ._image-airdrop {
        padding: 0 1em;
    }

    ._fav-airdrop {
        margin-top: .5em;

        span {
            padding: 0 0 0 0;
            font-size: 40px;
        }
    }

    .save-fav {
        position: absolute;
        text-align: center;

        i {
            margin-right: 0;
        }

        ._count-fav {
            font-size: 20px;
            color: var(--alternative-text-color);
            padding: 0;
            margin-top: -5px;
        }
    }

    ._image-nft {
        margin: 0 auto;
        border-radius: 30px;
        padding: 0;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25), 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        max-height: 395px;
    }
    ._image-nft-untitled {
        width: 270px;
        height: 367px;
        background-color: #ccc;
        border-radius: 30px;
        color: var(--black-color);
        display: flex;
        align-items: center;
    }

    ._image-token {
        margin-right: 8px;
        width: 17px;
        object-fit: contain;
    }

    ._name-nft {
        margin: 12px;
        height: 36px;
        object-fit: contain;
        font-size: 32px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    ._currency_value {
        text-align: center;

        ._count-fav {
            font-size: 20px;
            color: var(--alternative-text-color);
            padding: 0;
            margin-top: -5px;
        }
    }

    ._section_value {
        justify-content: center;
    }
    ._icon-verify {
        width: 16px;
        height: 16px;
        vertical-align: baseline;
    }
}
.breadcrumb {
    font-size: 1.2em;
    svg {
        width: .75em;
    }
}
.tab-profile {
    background-image: url('../images/bg-profile.png');
    background-position: top center;
    background-size: cover;
    padding: 2em;
    min-height: 620px;
    margin-top: 3em;

    .nav-link {
        text-transform: uppercase;
        color: var(--primary-text-color);
        font-size: var(--font-medium-x);
        border-bottom: rgba(255, 255, 255, .5) solid 1px;;
    }
    .my-nft-nav .nav-link {
        font-size: var(--font-large);
    }
    .my-nft-nav.nav-pills .nav-item {
        margin-bottom: 1em;
    }

    .left-tabs.nav-pills .nav-link {
        padding: 1em 0;
    }
    .nav-pills {
        padding: 0 2em;
        background-color: rgba(255, 255, 255, .2);
        border-radius: 12px;
        border:  rgba(255, 255, 255, .5) solid 1px;
        box-shadow: 4px 4px 0 rgb(0 0 0 / 25%);

        .nav-link {
            border-radius: 0;
            padding: .5em  0 .1em  0;

            &.active {
                background-color: transparent;
                color: var(--red-color);
                // border-bottom:  var(--red-color) solid 2px;
            }
        }

        .nav-item {
            cursor: pointer;
        }

        .nav-item:last-child {
            .nav-link {
                border-bottom: none;
            }
        }
    }

    .nav-tabs {
        border-bottom: none;
        justify-content: start;

        .nav-link {
            border-bottom: none;

            &.active,
            &:hover {
                background-color: transparent;
                border-color: transparent;
                color: var(--green-color);
                border-bottom: var(--green-color) solid 1px;
            }

        }
    }

    .profile-tab-content {
        &.tab-pane {
            border-radius: 24px;
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            border: solid 1px rgba(255, 255, 255, 0.2);
            background-color: rgba(255, 255, 255, 0.2);
            padding: 2em;
            min-height: 250px;
        }
    }

    .card-box-my-wallet-balance {
        width: 100%;
        margin-bottom: 1em;
        padding: 34.5px 34.5px 34.5px 34.5px;
        // padding: 34.7px 710.5px 70.3px 53.5px;
        object-fit: contain;
        border-radius: 50px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        background-color: rgba(255, 255, 255, 0.2);
        border: solid 1px var(--secondary-text-color);
        position: relative;


    }

    p.my-wallet-balance {
        font-size: 30px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }

    .address {
        width: auto;
        margin-bottom: 1em;
        padding: 10.5px 27.4px 10.5px 18.7px;
        object-fit: contain;
        border-radius: 50px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        background-color: rgba(255, 255, 255, 0.2);
        border: solid 1px #fff;
        position: relative;
        display: inline-block;

        &._settings {
            width: -webkit-fill-available;
            min-height: 300px;
        }
    }

    .address-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0 9.9px 0 0;
        font-size: var(--font-large);
        text-align: left;
        color: var(--primary-text-color);
        cursor: pointer;
        svg {
            vertical-align: bottom;
            font-size: var(--font-medium-x);
        }
    }
}

.general-setting-box {
    padding: 1.5em;

    p {
        font-size: 18px;
        line-height: 1.7;
        letter-spacing: normal;
        text-align: left;
        color: #828181;

    }

    .input-box {
        margin: .5em 0 1em 0;
        width: 100%;
        border: 0;
        outline: 0;
        border-bottom: 2px solid #626262;
        font-size: 1.4rem;
        background-color: transparent;
        color: #fff;
    }

    .row-input {
        width: 100%;
        // margin: 0px 0px 15px 0px;


    }

    .text-area-box {
        // margin: 10.6px 3px 56.3px 79px;
        padding: 34px 42px 48px 28px;
        width: 100%;
        object-fit: contain;
        border-radius: 20px;
        font-size: 1.4rem;
        border: solid 1px #626262;
        background-color: transparent;
        color: #fff;
    }

    .mui .react-phone-number-input__input {
        /* height: calc(0.3rem * 6); */
        height: unset;
    }

    span {
        font-size: 20px;
        // padding: 3px;
        color: red;
        display: none;
    }

    input:invalid[focused="true"] {
        border-bottom: 1px solid red;
    }

    input:invalid[focused="true"]~span {
        display: block;
    }

    textarea:invalid[focused="true"] {
        border-bottom: 1px solid red;
        border: solid 1px red;
    }

    textarea:focus {
        outline: none !important;
        border: solid 1px #626262;
        box-shadow: 0 0 0px transparent;
    }

}

.avatar-box, .cover-box {
    width: 252px;
    height: 252px;
    border-radius: 50px;
    background-color: #fff;
    position: relative;
    label {
        position: absolute;
        z-index: 10;
        width: 40px;
        height: 40px;
        bottom: 0;
        right: 1px;
        background: #fff;
        border: #ddd solid 1px;
        border-radius: 50%;
        &:after {
            content:"";
            background-image: url('../images/edit-2.svg');
            background-size: 100%;
            width: 20px;
            height: 20px;
            position: absolute;
            background-repeat: no-repeat;
            right: 0;
            left: 0;
            top: 8px;
            text-align: center;
            display: block;
            margin: 0 auto;

        }
    }
}

.cover-box {
    width: auto;
    height: auto;
    border-radius: 24px;
    img {
        border-radius: 24px;
    }
}
.avatar-box input {
    width: 252px;
    height: 252px;
    margin: 0;
}

.avatar-box-upload {
    background: url('../images/avatar-upload.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    width: 123px;
    height: 123px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    &._cover {
        background: url('../images/avatar-upload.svg');
        background-repeat: no-repeat;
        background-size: 100%;    
    }
}

.save-box {
    margin: 1em 0;

    button {
        min-width: 220px;
        padding: .57em;
        margin: 0 auto;
        display: block;
    }
}

.save-btn {
    background: url('../images/save-button-bg.svg');
    width: 350px;
    height: 100px;
    margin: 24px 269.8px 0 29.2px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

p.save-btn-text {
    position: absolute;
    left: 40%;
    top: 35%;

    object-fit: contain;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

hr.solid {
    margin: 56px 100px 0px 0px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.btn-nft {
    min-width: 300px;
}

.avatar_profile {
    border-radius: 50%;
    border: 1px solid var(--alternative-text-color);
}

.avatar-box-img {
    width: 252px;
    height: 252px;
    border-radius: 50px;
    // display: none;
}

.dropdown-country-code {
    color: black;

    background-color: white;
}

.avatar-inputfile {
    display: none;
}

._icon {
    &-heart {
        background-image: url('../images/icon-heart.svg');
    }
}

.nft-box-nav {
    .flex-column {
        flex-direction: row !important;
        background-color: transparent;
        border: none;
        padding-left: 0;

        .nav-item:first-child {
            .nav-link {
                padding-left: 0;
            }
        }

        .nav-link {
            padding-left: 0.5em;
            padding-right: 0.5em;
            border-bottom: none;
        }
    }
}

._dropdown-nft {
    .dropdown-menu.show {
        width: 100%;
        font-size: 1.2em;
    }
    button {
        text-transform: uppercase;
        font-size: var(--font-medium-x);
        width: 100%;
        background-color: transparent;
        border: #fff 1px solid;
        box-shadow: none !important;

        &:focus,
        &:active {
            background-color: transparent !important;
            box-shadow: none !important;
            border-color: #fff !important;
        }
    }
}

._my-collections {
    .collection {
        background: linear-gradient(113deg, rgba(97, 97, 97, 0.41) 15%, rgba(73, 73, 73, 0.34) 75%, rgba(0, 0, 0, 0.17) 100%);
        border-radius: 20px;
        padding: 1em;
        margin: 3em 1em 1em;
        position: relative;
        min-height: 414px;
        .avatar {
            max-width: 100px;
            margin: 0 auto;
            position: absolute;
            left: 0;
            right: 0;
            top: -2em;
            img {
                border-radius: 50%;
                border: 1px solid var( --alternative-text-color);
                height: 100px;
            }
        }
        .about {
            margin-top: 3.5em;
            text-align: center;
            h2 {
                font-size: var(--font-large);
                margin-bottom: 0;
                color: var(--primary-text-color)!important;
                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 90%;
                    display: inline-block;
                }
            }
            .owner {
                color: var(--green-color);
                font-size: var(--font-medium-x);
            }
            .description {
                color: var(--secondary-text-color);
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* number of lines to show */
                -webkit-box-orient: vertical;
                height: 40px;   
            }
        }
        .cover {
            margin-top: 2em;
            img {
                border-radius: 20px;
                // max-height: 187px;
            }

        }
        ._icon-verify {
            width: 16px;
            height: 16px;
            vertical-align: super;
        }
    }
}

.infinite-scroll-component  {
    overflow: inherit!important;
}
.verify-btn {
    color: var(--red-color);
    background: #fff;
    border-color: #fff;
    padding: 2px 6px;
    font-size:1rem;
    float: right;
    margin-top: -35px;
    cursor: pointer;
    position: relative;
    z-index: 10;
    border-radius: 20px;
    &:disabled {
        background-color: #979797;
        color: #3e3e3e;
        border-color: transparent;
    }
}

.icon-verify{
    width: 30px;
    float: right;
    margin-top: -30px;
    padding: 0 6px;
}
._flash-message {
    &.modal-alert .modal-content {
        min-height: 240px;
        background: linear-gradient(283deg, rgb(54 54 54 / 87%) 15%, rgb(46 44 44 / 43%) 75%, rgb(38, 38, 38) 100%);
        h2 {
            margin-top: 1rem;
        }
    }
}
.modal-verify { 
    text-transform: uppercase;
    .modal-title {
        font-size: 1.2rem;
        text-align: center;
    }
    .modal-header {
        border-bottom: 1px solid #2d2d2d;
        padding: 12px 0;
        display: block;
        position: relative;
    }
    ._icon {
        width: 16px;
        height: 16px;
        position: absolute;
        right: -15px;
        top: 0;
    }
    .modal-content {
        padding:1rem;
        border-radius: 24px;
        background-color: var(--backgroud-primary-color);
        box-shadow: 0 -4px 15px 0 #e31f26;
        border: solid 1px #e31f26;
        .mobile-number {
            background-color: #191919;
            color: #fff;
            font-size: var(--font-large);
            padding: 0.5rem 2rem;
            border-radius: 20px;
            display: inline-block;
            margin-bottom: 2em;
        }
        .btn-nft {
            min-width: 220px;
            display: block;
            margin: 0 auto;
            text-transform: uppercase;
        }
    }

}
.resend-otp {
    display: block!important;
    margin: 1em 1em 2em;

    span {
        padding-right:12px;
    }
    button {
        // display: block;
        // margin: 0 auto;
        border: none;
        border-radius: 4px;
        // color: var(--red-color);
        // background-color: transparent;
    }
}
.otp-input {
    padding: 1em 0;
    border-top:1px solid #2d2d2d;
    display: inline-block;
    min-width: 280px;
    div:nth-of-type(1) {
        justify-content: center;
    }

    input[type="number"], .otp{
        width: 46px!important;
        height: 48px!important;
        font-size: 24px;
        margin-right: 8px;
        display: inline-block;
        border:#3D3D3D solid 1px;
        background-color: #1B181A;
        text-align: center!important;
        padding: 2px;
        color: #fff;
        border-radius: 8px;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}

._contries-list {
    border-bottom: 1px solid var(--secondary-text-color);
    position: relative;
    &:after {
        content:"";
        position: absolute;
        background-color: var(--secondary-text-color);
        height: 25px;
        width: 1px;
        right: 0;
        top: 10px;
    }
    .form-select {
        background-image: url('../images/arrow-1.png');
        background-size: 10px;
        &:disabled {
            background-color: transparent;
            opacity: .7;
        }
    }
    select { 
        max-width: 150px;
        background-color: transparent;
        border: none;
        color: #fff;
        padding: 5px;
        font-size: 1.15em;
        padding-right: 30px;
    }
}
.phone-box {
    width: 85%;
    input {
        padding-left: 10px!important;
    }
}
.noti {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    display: inline-block;
    color:#fff;
    text-align: center;
    vertical-align: middle;
    background-color: var(--red-color);
    font-size: .8rem;
    left: 35px;
    position: absolute;
    line-height: 10px;
}
.shares a , a.dropdown-item {
    position: relative;
}

a.dropdown-item  .noti {
    left: 100px;
    height: 10px;
    width: 10px;
    font-size: .5rem;
    top: 13px;
}

.nav-top-profile .noti {
    width: 12px;
    height: 12px;
    font-size: .65rem;

}
.edit-profile {
    span {
        font-size: .9rem;
        color:var(--red-color);
        background-color: #FFB7B7;
        padding: 5px 10px;
        border-radius: 6px;
        vertical-align: middle;
    }
}

._icon-alert {
    background-image: url('../images/icon-alert.svg');
    width: 20px;
    margin-left: 0;
    height: 20px;
    margin-right: 0;
}
@media (max-width: 576px) {
    .tab-profile .address._settings {
        border-radius: 20px;
        padding: 0;
        margin-top: 1.5em;
    }

    .general-setting-box {
        padding: 1em;
    }

    ._logo-collection {
        padding: 0;
    }

    .profile-section .collection-profile .hash-profile {
        display: inherit;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .profile-section .collection-profile {
        padding: 0 1em 1em 0;
    }

    .profile-section {
        margin-top: 40px;
    }
    .profile-section,
    .tab-profile {
        padding: .5em 0 2em 0;;
    }

    .modal-private-code .modal-content {
        border-radius: 24px;
    }

    .tab-profile {
        .profile-tab-content {
            &.tab-pane {
                padding: 0.75em;
            }
        }

        .card-box {
            padding: 0.5em;
            margin-top: .5em;
            margin-bottom: .5em;
        }
    }

    .tab-profile .card-box ._image-airdrop {
        padding-top: 1em;
    }

    .tab-profile .card-box ._name-nft {
        margin: .2em;
    }

    .tab-profile .card-box ._fav-airdrop {
        margin-top: .5em;
        margin-bottom: 1em;
    }

    .profile-section ._icon {
        width: 22px;
    }

    .profile-section .collection-profile h2 {
        font-size: 1.5em;
    }

    .tab-profile {
        .tab-content {
            margin-top: 1.5em;
            h2 {
                text-align: center;
            }
        }
    }

    .profile-section .collection-profile .description {
        margin-top: 0;
    }

    .tab-profile .nav-pills {
        background-color: transparent;
    }

    .tab-profile .nav-link {
        border-color: rgb(175 175 175 / 46%);
    }
}

.kyc-profile {
    margin: 5em auto;
    padding-top: 60px;
    min-height: 650px;
    text-align: center;

    .responsive-iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
}