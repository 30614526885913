:root {
    
    --font-friday:'FC Friday';
    --font-normal: Helvetica, Arial, sans-serif;

    --backgroud-primary-color:#000;
    --background-secondary-color:#212121;
    --primary-text-color: #fff;
    --secondary-text-color:#858585;
    --alternative-text-color:#afafaf;
    --footer-background-color:#4d4d4d;
    --red-color:#ff020b;
    --green-color:#33FB21;
    --black-color:#000;
    --gray-color:#afafaf;
    
    --s-radius:50px;

    --font-small:.75em;
    --font-medium:1em;
    --font-medium-x:1.2em;
    --font-large:1.5em;
    --font-large-x:2em;
    --font-large-xl:3em;
}

/*  Override Class  */

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    background-color: transparent!important;
    border:none;
    border-bottom: 1px solid #fff;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}
 
._yellow {
    color: #be8a11;
}
._white {
    color:var(--primary-text-color)!important;
}
._red {
    color:var(--red-color)!important;
}
._text-secondary {
    color:var(--background-secondary-color);
}
._gray {
    color:var(--gray-color);
}
._green {
    color:var(--green-color)!important;
}
._font-small {
    font-size: var(--font-small)!important;
}
._font-medium {
    font-size: var(--font-medium)!important;
}
._font-medium-x {
    font-size: var(--font-medium-x)!important;
}
._font-large {
    font-size: var(--font-large)!important;
}
._font-large-x {
    font-size: var(--font-large-x)!important;
}