 .header-section {
    margin-top: 60px;
    background-position: 0 72px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: 100%;
    min-height: 350px;
    padding-top: 3em;
    padding-bottom: 2em;
    &:after {
        content: "";
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.42);
        z-index: 0;
        position: absolute;
        top: 0;
    }
    .header-section-content {
        position: relative;
        z-index: 1;
    }
    h2 {
        font-size: var(--font-large-xl);
    }
    .collection-title {
        font-size: var(--font-large-xl);
    }
    .collection-avatar {
        border-radius: 50%;
        display: block;
        padding: 1em;
    }
    .collection-by {
        // width: 236px;
        height: 23px;
        font-weight: normal;
        object-fit: contain;
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #33fb21;
      }
      .collection-desc {
          font-size: var(--font-medium-x);
          margin-top: .5em;
      }
      .filter-box {
          text-align: right;
      }
      .icon-action {
        width: 50px;
        height: 50px;
        object-fit: contain;
        .favorite {
            margin-left: 22px;
            margin-top: 30px;
        }
        .share{
            margin-left: 22px;
            margin-top: 30px;
        }
      }
      ._icon {
        display:inline-block;
        width:50px;
        height:50px;
        background-repeat:no-repeat;
        background-size:100%;
        margin-right: 22px;
        margin-left: 0;
        cursor: pointer;
        &-share {
            background-image:url('../images/icon-share.svg');
        }
        &-fav {
            background-image:url('../images/icon-fav.svg');
        }
        &-tweet {
            background-image:url('../images/icon-tweet.svg');
        }
        &-star {
            background-image:url('../images/icon-star.svg');
        }
        &-settings {
            background-image:url('../images/icon-setting.svg');
        }
        &-verify {
            background-image:url('../images/icon-verify.svg');
            vertical-align: baseline;
            width: 25px;
            height: 25px;
        }
        &-star-active {
            background-image: url('../images/icon-star.svg'); //icon-heart-active
            background-color: red;
            border-radius: 50%;
        }
    }
}
.box-collection-info {
    position: relative;
    margin-top: 1em;
}
.box-collec-detail {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
    height: 97px;
    max-width:350px;
    background-image:  radial-gradient(125.38% 125.38% at 52.87% 50%, rgba(255, 255, 255, 0) 38.76%, #FFFFFF 76.29%);
    box-shadow:  0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 45px;
    position: relative;
    margin-bottom: 1em;
    .center{
        text-align: center;
        margin-top:.5em;
    }
    .value {
        cursor: pointer;
        object-fit: contain;
        font-size: 30px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #fff;
      }
      .value-title {
        object-fit: contain;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #9a9a9a;
        cursor: pointer;
      }
}

.nav-link {
    text-transform: uppercase;
    color:var(--primary-text-color);
    font-size: var(--font-medium-x);
    border-bottom: var(--alternative-text-color) solid 1px;
}

.nav-tabs {
    border-bottom: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .nav-item {
        margin-left: 1em;
    }
    .nav-link { 
        border-bottom: none;
        background: radial-gradient(125.38% 125.38% at 52.87% 50%, rgba(255, 255, 255, 0) 25.75%, #FFFFFF 76.29%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 50px;
        padding-left: 2em;
        padding-right: 2em;
        opacity: 0.5;
        &.active , &:hover{
            background-color: transparent;
            box-shadow: 0px 0px 25px #e31f26;
            background: rgb(227, 31, 38);
            background: linear-gradient(63deg, rgba(227, 31, 38, 0.2931766457) 0%, rgb(227, 31, 38) 45%, rgba(227, 31, 38, 0.3940169818) 87%);            border-color: transparent;
            color:var(--white-color);
            border-radius: 50px;
            border-bottom: var(--red-color) 1px solid;
            border-color: var(--red-color);
            opacity: 1;
        }
        
    }
}

.tab-content { 
    &.tab-pane {
        border-radius: 24px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-color: rgba(255, 255, 255, 0.2);
        padding: 2em;
        min-height: 250px;
    }
}

.sum-items {
    text-align: left;
    color: #868686;
    font-size: var(--font-medium-x);
    cursor: pointer;
  }
.filter-box {
    text-align: right;
}
.filter-section {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: .5px solid #383838;
    margin-top: 1em;
    .input-search {
        padding-left: 40px;
        // background-image: url('../images/background-input-search.png');
        // background-image: radial-gradient(125.38% 125.38% at 52.87% 50%, rgba(255, 255, 255, 0.15) 38.76%, #FFFFFF 76.29%) ;
        background-image: radial-gradient(125.38% 125.38% at 52.87% 50%, rgba(255, 255, 255, 0.15) 38.76%, #ffffff2b 76.29%);
        background-repeat: no-repeat;
        background-size: contain;
    }
    .filter-item {
        background-image: url('../images/background-item.png');
        background-repeat: no-repeat;
        background-size: contain;

    }
    .input-search,
    .filter-item select {
        border: solid 1px transparent;
        background-color: #000;
        color:var(--primary-text-color);
        border-radius: 30px;
        // opacity: .5;
        
        &::placeholder {
            // color:var(--primary-text-color)
        }
        &.form-select {
            background-image: url('../images/icon-arrow-down.svg');
        }
    }
    .filter-item select {
        background-color: transparent!important;
    }

    .dropdown-1 {
        opacity: 0.6;
        width: 270px;
        border-radius: 43px;
        
    }
    .dropdown-2 {
        opacity: 0.6;
        width: 270px;
        border-radius: 43px;
    }
    .dropdown-width {
        width: 100%;
    }
}

.section-control-item{

    padding-top: 20px;
    padding-bottom: 20px;
    border-top: .5px solid #383838;
    border-bottom: .5px solid #383838;
    ._icon{
        width: 40px;
        height: 40px;
    }
}

.section-spacer{
    margin: 50px;
}

.activity {
    min-height: 500px;
}
.border-left {
    border-left: 1px #9c9c9c solid;
}
._search-item {
    position: relative;
     .icon-search-item {
        display:inline-block;
        width:24px;
        height:24px;
        background-repeat:no-repeat;
        background-size:100%;
        margin-left: 16px;
        vertical-align: middle;
        background-image:url('../images/icon-search.svg');
        position: absolute;
        left: -6px;
        top: 8px;
    }
}
@media (max-width: 576px) { 

    ._box-item-nft {
        margin-bottom: 25px;
    }

    .nav-tabs .nav-link {
        padding-left: .5em;
        padding-right: .5em;
        font-size: 1em;
    }
    .nav-tabs .nav-item {
        margin-left: .5em;
    }
    .header-section .collection-title {
        text-align: center;
    }
    .header-section .collection-avatar {
        margin: 0 auto;
        border-radius: 50%;
        display: block;
        height: 185px;
        width: 185px;
        background-size:auto;
    }
    .box-collec-detail .value {
        font-size: var(--font-large);
    }
    .box-collec-detail .value-title {
        font-size: var(--font-small);
    }
    .box-collec-detail {
        max-width: 100%;
        height: 64px;
        background-size: 100%;
        background-repeat: no-repeat;
        padding-top: 8px;
        margin-top: 25px;
    }
    ._search-collection {
        margin-bottom: 1.2em;
    }
    .box-item {
        // margin-bottom: 20%;
    }
    .header-section:after {
        height:200%;

    }
}