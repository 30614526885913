.main-section {
    margin-top: 60px;
    background-image: url('../images/bg-login.png');
    background-size: 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 540px;

    .main-section-top {
        padding-top: 3em;
    }
    .main-section-bottom {
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 30px;
    }

    .shares-box {
        display: flex;
        margin-top: 45px;
        justify-content: center;
    }

    ._icon {
        display:inline-block;
        width:50px;
        height:50px;
        background-repeat:no-repeat;
        background-size:100%;
        margin-right: 22px;
        cursor: pointer;
        &-share {
            background-image:url('../images/icon-share.svg');
        }
        &-fav {
            background-image:url('../images/icon-fav.svg');
        }
        &-tweet {
            background-image:url('../images/icon-tweet.svg');
        }
        &-fav-active {
            background-image: url('../images/icon-fav.svg'); //icon-heart-active
            // background-image: url('../images/icon-heart-active.svg');
            background-color: red;
            border-radius: 50%;
        }
    }

    .top-main-logo-airdrop {
        background-image: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0) 35%, #fff 225%);
        border-radius: 50px;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 40px;

        .top-info-box {
            display: flex;
            ._icon-verify {
                width: 20px;
                height: 20px;
                vertical-align: inherit;
                margin-right: 0;
            }
        }

        .top-info-box .item {
            padding: 20px 0;
            text-align: center;
            width: 100%;
        }
        .colection-info {
            padding-left: 3em;
            padding-right: 3em;
        }
        .tcclogo-group {
            border-radius: 80px;
            object-fit: cover;
            object-position: 50% 50%;
        }
        .tcc-title {
            object-fit: contain;
            font-size:2.5em;
            text-align: center;
            color: #fff;
            text-transform: uppercase;
        }

        .tcc-sub-title {
            object-fit: contain;
            font-size: var(--font-large);
            text-align: left;
            color: #fff;
            text-transform: uppercase;
        }

        .tcc-sub-title-2 {
            object-fit: contain;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #33fb21;
            text-transform: uppercase;
        }

        img.viewers {
            width: 24px;
            height: 24px;
            margin-right: 7px;
            vertical-align: top;
        }

        p.viewers {
            display: inline;
            font-size: var(--font-medium-x);
            text-align: center;
            color: #fff;
            text-transform: uppercase;
        }

        .group-viewers {
            width: 1px;
            margin: 0px 0px 22px 22px;
            background-color: #afafaf;
        }

        .group-box {
            width: 22px;
            height: 50px;
        }

        img.group-view {
            width: 50px;
            height: 50px;
            margin: 44px 22px 0 17px;
            object-fit: contain;
        }

        .group-view-box {
            background-color: antiquewhite;
        }

        .group-view-item {
            justify-content: center;
            margin: 0 auto;
            width: 100%;
        }

        .claim-airdrop {
            // background-image: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0) 35%, #fff 225%);
            background-image:url('../images/background-airdrop.png') ;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 55px 0px;
            border-radius: 50px;
            padding:0 .75em;
            margin-top: 2em;

            .btn-claim {
                box-shadow: 0px 0px 25px #e31f26;
                background: rgb(227, 31, 38);
                background: linear-gradient(63deg, rgba(227, 31, 38, 0.2931766457) 0%, rgb(227, 31, 38) 45%, rgba(227, 31, 38, 0.3940169818) 87%);
                color: var(--white-color);
                border-radius: 50px;
                border-bottom: var(--red-color) 1px solid;
                border-color: var(--red-color);
                font-size: 1.8em;
                min-width: 200px;
                line-height: 1.4;
            }
        }

        .v-separator {
            content: "";
            display: inline-block;
            width: 0px;
            height: 17px;
            border-left: 1px solid #676767;
            padding: 0;
        }
    }
 
}
._airdrop {
    text-align: right;

    span {
        font-size: .5em;
        display: block;
        opacity: .6;
        margin-top: -5px;
    }
    h2 {
        font-size: 2.5em;
        padding: 0;
        text-align: center;
        margin-left: 1.5em;
    }
}
.description-box {
    margin: 35px 0px 0px 0px;
    background-image: radial-gradient(140.67% 94.53% at 50% 50%, rgba(255, 255, 255, 0) 27.77%, #FFFFFF 100%);
    border-radius: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 40px;
}

p.detail-box {
    margin: 7.5px 0 0;
    object-fit: contain;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #919191;
}

.claim-box {
    margin: 38.4px 0px 50px 0px;
    background-image: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0) 35%, #fff 225%);
    border-radius: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 40px;

    h2, h3 {
        text-transform: uppercase;
    }

    span.how-to-claim {
        object-fit: contain;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #afafaf;
        cursor: pointer;
    }

    img.group-howto-claim {
        margin: 0px 38px 42px 0;
        object-fit: contain;
    }
}
.nft-item-cliam {
    img {
        border-radius: 24px;
        max-width: 340px;
    }
}
.box-hash-confirm {
    display: flex;
    padding: .6rem;
    border: #555 solid 1px;
    border-radius: 10px;
    text-align: left;
    .hash-address {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: calc(65% - 10px);
    }
}
._nft-claim-success {
    &.modal-alert .modal-content {
        // padding-top: .5rem;
        // padding-bottom: .5rem;

        .btn-nft {
            min-width: auto;    
            margin-right: 1rem;
        }
    }
}
.btn-go-nft {
    height: 38px;
}
.btn-loading {
    display: flex;
    margin: 0 auto;
    border-radius: 24px;
    width: 50%;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 2px auto;
  font-size: 2px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


@media (max-width: 576px) { 
    .btn-loading {
        width: 60%;
    }
    .main-section .top-main-logo-airdrop {
        padding: 1.5em 1em;
    }
    .main-section {
        background-size: cover;
    }
    .main-section .top-main-logo-airdrop .colection-info {
        padding-left: 0;
        padding-right: 0;
    }
    .claim-box img.group-howto-claim {
        width: 100%;
        height: auto;
        margin: 0;
    }
    .claim-box {
        padding: 1.5em;
    }
    ._airdrop h2 {
        font-size: 1.5em;
        margin-left: 0;
    }
    .main-section .top-main-logo-airdrop .claim-airdrop {
        background-position: 0;
        background-size: 100%;
    }
    .main-section .top-main-logo-airdrop .tcclogo-group {
        border-radius: 24px;
    }
    .box-hash-confirm .hash-address {
        width: 200px;
    }
    .btn-go-nft{
        width: 300px;
    }
    .wait-text {
        padding-right: 25px;
    }
}

@media (max-width: 412px) { 
    .modal-alert .modal-content {
        padding: 1em;
    }
    
}

@media (max-width: 360px) { 

    .btn-nft {
        min-width: 100%;
    }
    .modal-alert .modal-content {
        padding: 1em;
    }
    .box-hash-confirm .hash-address {
        width: calc(100% - 300px);
    }
    .btn-go-nft {
        width: 100%;
    }
}