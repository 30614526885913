 
.footer {
    background-color: var(--footer-background-color);
    a, a:visited, a:active, a:focus {
        color: var(--primary-text-color);
        &:hover {
            color: var(--primary-text-color);
            text-decoration: underline;
        }
    }
    &-inner { 
        padding: 2em 1em 1em;
        ul {
            margin-left: 0;
            padding-left: 0;
        }
        h2 {
            font-size: var(--font-large);
            color:var(--primary-text-color);
        }
    }
    .box-copyright {
        height: 50px;
    }
    .copyright {
        position: relative;
        text-transform: uppercase;
        span {
            position: absolute;
            left: 0;
            right: 0;
            display: inline-block;
            margin: 0 auto;
            text-align: center;
            background-color: var(--footer-background-color);
            width: 345px;
            z-index: 1;
            font-size: .7em;
            font-family: Helvetica, sans-serif, Arial;
            line-height: 1.7;
        }
        &:after {
            content:'';
            border-bottom: #fff solid 1px;
            position: absolute;
            z-index: 0;
            width: 100%;
            height: 1px;
            top:10px;
            
        }
    }
 }

 @media (max-width: 576px) { 

 .footer .copyright span {
    width: 320px;
 }

.footer .copyright:after {
    width: auto;
 }
}