
.policy-title {
    color: #fff;
    font-size: var(--font-large);
    // margin-left: 40px;
    // margin-top: -30px;
    display: block;
  }
  
.policy-title .text-style-1 {
  color: #33fb21;
  text-decoration: underline;
}
.list-logins {
  background-color: transparent;
  border-color: var(--alternative-text-color);
  padding-left: 0;
  padding-bottom: 1em;
  padding-top: 1em;
  display: flex;
  align-items: center;
}
.card-center {
    background: rgba(0,0,0,0.5);
    background-color: rgba(0,0,0,0.5);
    width: 600px;
    margin: 20px auto 0 auto;
    padding: 30px 40px 25px 40px;
    border-radius: 50px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border-radius: var(--s-radius);
    // background: url('../images/bg-gradient.svg');
    // background:linear-gradient(113deg, rgba(97, 97, 97, 0.41) 15%, rgb(73 73 73 / 34%) 75%, rgba(0, 0, 0, 0.17) 100%);
    background-image: radial-gradient(125.38% 125.38% at 52.87% 50%, rgba(255, 255, 255, 0) 38.76%, #FFFFFF 76.29%) ;
    background-size: contain;
    background-repeat: no-repeat;
}

.textWallet {
  font-family: 'FC Friday';
  font-size: 30px;
  text-align: left;
  color: #fff;
  padding: .75em;
}

.most-popular {
  width: 250px;
  height: 40px;
  margin: 9.3px 0px 36.5px 30.6px;
  object-fit: contain;
  border-radius: 50%;
}

.most-popular-text {
  width: 172px;
  height: 31px;
  flex-grow: 0;
  font-family: 'FC Friday';
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #33fb21;
}

.logo {
  max-width: 50px;
}

.logo-popular {
  max-width: 250px;
}

.login-section {
  margin-top: 60px;
  background-image: url('../images/bg-login.png');
  background-size: 100%;
  background-position: 0 -80px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 640px;

  a, a:visited {
    color:var(--green-color);
  }
  .box-login-select {
    margin: 5em 0 0;
    padding: 3em 0;
    
    .list-group-item {
      cursor: pointer;
    }
  }
  .box-policy {
    max-width: 600px;
    margin: 0 auto;
    text-transform: uppercase;
    h2 {
      margin-bottom: 1.2em;
      font-size: 2.5em;
    }
  }


  ._icon {
    display:inline-block;
    width:20px;
    height:20px;
    background-repeat:no-repeat;
    background-size:100%;
    margin-right: 22px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 18px;
    &-eye {
      background-image:url('../images/icon-eye.svg');
    }
    &-close-eye {
      background-image:url('../images/icon-close-eye.svg');
    }
  }
}

.container-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 12px;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -5px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: rgba(0, 0, 0, .35);
  border:var(--green-color) 1px solid;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color:  rgba(0, 0, 0, .35);
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: #33fb21;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 6px;
  height: 15px;
  border: solid #075c00;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.login-email-form {
  .form-label {
    color:var(--secondary-text-color);
    text-transform: uppercase;
  }
  input.form-control:focus, textarea.form-control:focus, textarea:focus,
  input[type=text]:focus, input[type=password]:focus {
    box-shadow: inset 0 -1px 0 transparent!important;
    outline: 0!important;
    border-top:none!important;
    border-left:none!important;
    border-right:none!important;

  }
  .form-control {
    background-color: transparent;
    border:none;
    border-radius: 0;
    padding-left: 0;
    color:var(--primary-text-color);
    border-bottom: 1px solid var(--secondary-text-color);
    font-size: var(--font-medium-x);
    &:disabled {
      opacity: .7;
    }
  }
  .btn-nft {
    min-width: 300px;
  }
}

@media (max-width: 576px) { 

  .login-section .box-login-select {
    margin-top: 0;
    margin-bottom: 1em;
  }
  .login-section .box-policy {
    margin-bottom: 10px;
  }
  .policy-title {
    font-size: var(--font-medium);
  }
  .card-center {
    width: auto;
    margin: 0;
    padding: 1.2em;
    margin-bottom: 2em;
  }
  .login-section {
    background-position: center bottom;
    background-size: cover;
    padding: 1em;
    min-height: auto;
  }
  .textWallet {
    margin: 0;
    font-size: var(--font-medium-x);
    padding-left: .25em;
  }
  .logo-popular {
    max-width: 150px;
  }

}