@font-face {
    font-family: 'FC Friday';
    src: url('../fonts/FC_Friday/FCFridayRegular.woff2') format('woff2'),
        url('../fonts/FC_Friday/FCFridayRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FC Friday';
    src: url('../fonts/FC_Friday/FCFridayMedium.woff2') format('woff2'),
        url('../fonts/FC_Friday/FCFridayMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FC Friday';
    src: url('../fonts/FC_Friday/FCFridayMediumItalic.woff2') format('woff2'),
        url('../fonts/FC_Friday/FCFridayMediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FC Friday';
    src: url('../fonts/FC_Friday/FCFridayBold.woff2') format('woff2'),
        url('../fonts/FC_Friday/FCFridayBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FC Friday';
    src: url('../fonts/FC_Friday/FCFridayItalic.woff2') format('woff2'),
        url('../fonts/FC_Friday/FCFridayItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'FC Friday';
    src: url('../fonts/FC_Friday/FCFridayBoldItalic.woff2') format('woff2'),
        url('../fonts/FC_Friday/FCFridayBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'impact';
    src: url('../fonts/impact-webfont.woff2') format('woff2'),
         url('../fonts/impact-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}