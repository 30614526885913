.discover-section {
    padding-bottom: 3em;
    ._icon {
        position: relative;
        top:0;
        margin-right: 0;
        width: 18px;
        height: 18px;
        vertical-align: baseline;
    }
    .search-collection {
        margin-bottom: 2em;
    }
    .collection-cateogry {
        display: flex;
        justify-content:center;
        margin-bottom: 2em;
        .category {
            cursor: pointer;
            margin-right: .5em;
            padding:.25em 1.2em;
            font-size: var(--font-large);
            border-radius: 20px;
            background: linear-gradient(113deg, rgba(32, 28, 28, 0.92) 15%, rgba(22, 22, 22, 0.57) 75%, rgba(0, 0, 0, 0.83) 100%);
            &.active {
                background: linear-gradient(63deg, rgba(227,31,38,0.2931766456582633) 0%, rgba(227,31,38,1) 45%, rgba(227,31,38,0.39401698179271705) 87%);
            }
        }
    }
    .title {
        padding-top: .5em;
        font-size: var(--font-large-xl);
        text-align: conter;
    }
    ._search-c {
        max-width: 50%;
        margin: 0 auto;
        margin-top: 2em;
        input[type="search"] {
            border-radius: 24px;
            color: var(--primary-text-color);
            border: 1px solid rgba(175,175,175,.6);
            padding: 0.2em 0.75em;
            background: linear-gradient(113deg, rgba(32, 28, 28, 0.92) 15%, rgba(22, 22, 22, 0.57) 75%, rgba(0, 0, 0, 0.83) 100%);
            &:focus {
                outline: none!important;
                box-shadow: none;
            }
        }
    }
}
@media (max-width: 576px) {
    .login-section.discover-section {
        padding:0;
    }
    .discover-section ._search-c {
        max-width: 100%;
    }
    .discover-section .collection-cateogry .category {
        padding: .25em 1.75em;
        font-size: var(--font-medium-x);
    }

}