.box-item {
   background-image: url('../images/box-item-nft.svg');
   background-repeat: no-repeat;
   padding-bottom: 2em;
   padding-top: 1em;
   background-size:contain;
   background-position: center;
   height: 100%;
   position: relative;
   .icon-play{
      position: absolute;
      font-size: 2.5rem;
      color: #000;
      right: 10%;
      bottom: 35%;
   }
   .box-collection-item {
      position: absolute;
      bottom: 20px;
   }
   &._favourite-box {
      padding-top: .5em;
      margin-bottom: 1em;
      .nft-image {
         padding: 2em;
      }
      .nft-name {
         height: auto;
      }
   }
}
.nft-image {
   margin: 0 auto;
   border-radius: 50px;
   // height: 280px;
   // width: 280px;
   display: block;
   padding: 2em;
}
.nft-name {
   text-overflow: ellipsis;
   font-size: var(--font-large);
   margin-top: 10px;
   padding: 0 2em;
   height: 30px;
   overflow: hidden;
   -webkit-line-clamp: 1;
   display: -webkit-box;
   -webkit-box-orient: vertical;
}

.nft-untitled {
   margin: 1em auto;
   border-radius: 10%;
   width: 242px;
   height: 242px;
   padding:1em;
   background-color: #ccc;
   border-radius: 30px;
   color: var(--black-color);
   display: block;
   align-items: center;
}

.nft-untitled-name {
   text-overflow: ellipsis;
   font-size: var(--font-large);
   margin-top: 10px;
   padding: 0 2em;
   height: 60px;
   overflow: hidden;
   text-align: center;
}

   .airdrop {
      text-align: center;
      font-size: var(--font-large);
      span {
         display: block;
         font-size: var(--font-small);
         opacity: .6;
      }
   }
   .favorite{
      border-right: 1px solid #404040;
      border-left: 1px solid #404040;
      img {
         width:20px;
     }
   }
   .col-3{
      .center{
         margin: 0 auto;
         text-align: center;
      }
      .favorite-sum {
         text-align: center;
         color: #828181;
         display: block;
      }
      .btn-claim {
            color:var(--primary-text-color);
            border-radius: 24px;
            border-color: var(--red-color);
            border-width: 1px;
            border-style: solid;
            padding-left: .75em;
            padding-right: .75em;
            height: 45px;
            font-size: 1.3em;
            box-shadow: 0px 0px 25px #E31F26;
            background: rgb(227,31,38);
            background: linear-gradient(63deg, rgba(227,31,38,0.2931766456582633) 0%, rgba(227,31,38,1) 45%, rgba(227,31,38,0.39401698179271705) 87%);
            &._sold-out {
               background: var(--primary-text-color);
               color: var(--red-color);
               border-color: transparent;
            }
      }
   }


@media (max-width: 576px){
   .nft-image{
      // height: 240px;
      // width: 240px;
      display: block;
   }
   .box-item{
      padding-bottom: 10px;
      min-height: auto;
      padding-top: 1.5em;
   }
}
