.home-section {
    margin-top: 60px;
    background-image: url('../images/home-background.webp');
    background-position: -280px 466px;
    background-size: cover;
    .container {
        max-width: 1140px;
    }
    .home-coutdown {
        background-image: url('../images/bg-ink.webp');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position:0 -50px;
    }
    .home-banner {
        background-image: url('../images/banner-home.webp');
        height: calc(100vh - 72px);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: 0 10px;
    }
    .info-nft-header {
        position: absolute;
        right: 0;
        img {
            max-width: 590px;
        }
    }
    .nft-tcc-discover {
        font-size: var(--font-large);
        text-transform: uppercase;
        padding-top: 4em;
        h2 {
            font-size: var(--font-large-xl);
        }
        .btn-discover {
            color:var(--primary-text-color);
            border-radius: 24px;
            border-color: var(--red-color);
            border-width: 1px;
            border-style: solid;
            padding-left: 2.5em;
            padding-right: 2.5em;
            height: 50px;
            background: rgb(227,31,38);
            background: linear-gradient(63deg, rgb(227 31 38 / 9%) 0%, rgb(227, 31, 38) 45%, rgb(227 31 38 / 9%) 87%)
        }
    }
}
.specail-color-text {
    text-shadow:0 0 13px #e31f26;
}

/* HomeContent.js */
.content-inner {
    font-size: var(--font-large);
    h2 {
        color: var(--red-color);
        font-size:2.2em;
    }
    .tcc-brand-slogan {
        padding: 1em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 100%;
        max-width: fit-content;
        h2 {
            font-size: var(--font-large);
        }
    }
    .tcc-nft-logo {
        width: 100%;
        max-width: 450px;
    }
    .info-category-nft {
        max-width: 860px;
        margin: 0 auto;
        display: block;
    }
    .sub-title {
        text-transform: uppercase;
        font-size: var(--font-medium);
    }
    .sub-content {
        padding: 1.2em 2em 0;
        p {
            margin-bottom: 1.5em;
        }
    }
    ._text-quot {
        font-size: 1.8em!important;
    }
    .why-content {
        ul {
            li {
                background: url('../images/icon-check.svg') no-repeat left top;
                background-position: 0 5px;
                list-style: none;
                margin: 0;
                padding: 0 0 3px 30px;
                line-height: 1.2;
                margin-bottom: .5em;
                font-size: .798em;
                color:#afafaf;
                span {
                    display: block;
                    color: var(--red-color);
                }
            }
        }
    }
    .how-to-content {
        h2 {
            color:var(--primary-text-color);
            font-size: 2.2em;
            font-weight: 200;
            padding-bottom: .45em;
            text-shadow: 0 0 13px #e31f26;
        }
        .card-box {
            padding: 1.2em;
            padding-top: 3em;
            border-radius: var(--s-radius);
            background: url('../images/bg-gradient.svg');
            background-size: auto;
            background-repeat: no-repeat;
            font-size: var(--font-small);
            text-align: center;
            position: relative;
            color:#afafaf;
            height: 275px;
            .icon {
                width: 80px;
                height: 80px;
                position: absolute;
                top: -35px;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
            .icon-wallet-c {
                background: url('../images/icon-wallet-circle.webp');
                background-size: 100%;
            }
            .icon-collection-c {
                background: url('../images/icon-collection-circle.webp');
                background-size: 100%;
            }
            .icon-image-c {
                background: url('../images/icon-image-circle.webp');
                background-size: 100%;

            }
            .icon-bag-c {
                background: url('../images/icon-bag-circle.webp');
                background-size: 100%;
            }
            h3 {
                text-align: center;
                color:var(--primary-text-color);
                border:1px solid var(--red-color);
                padding:.37em .5em;
                background: rgb(227,31,38);
                background: linear-gradient(63deg, rgba(227,31,38,0.2931766456582633) 0%, rgba(227,31,38,1) 45%, rgba(227,31,38,0.39401698179271705) 87%);
                box-shadow: 0px 0 20px var(--red-color);
                border-radius: var(--s-radius);
                text-transform: uppercase;
                font-size: var(--font-medium);
            }
         }
    }
    .box-partner {
        color:#afafaf;
        background-color: rgba(77,77,77,.5);
        border-radius: 110px;
        padding: 1em 2em;
        max-width: 80%;
        text-align: center;
        font-size: var(--font-medium);
        margin: 0 auto;
        line-height: 1.2;
        h3 {
            color: var(--primary-text-color);
        }
        button {
            height: 45px;
            border: 1px solid var(--red-color);
            box-shadow: 0px 0px 25px var(--red-color);
            background: linear-gradient(63deg, rgba(227, 31, 38, 0.09) 0%, rgb(227, 31, 38) 45%, rgba(227, 31, 38, 0.09) 87%);
        }
    }
    .tcc-info-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 100%;
        img {
            width: 100%;

        }
    }
}
.btn-nft {
    color:var(--primary-text-color);
    border-radius: 24px;
    border-color: var(--red-color);
    border-width: 1px;
    border-style: solid;
    padding: .25em 1em;
    background: rgb(227,31,38);
    background: linear-gradient(63deg, rgba(227,31,38,0.2931766456582633) 0%, rgba(227,31,38,1) 45%, rgba(227,31,38,0.39401698179271705) 87%);
}
.btn-green {
    color:var(--black-color);
    border-radius: 24px;
    border-color: var(--green-color);
    border-width: 1px;
    border-style: solid;
    padding: .25em 1em;
    background:  rgb(50 250 33);
    background: linear-gradient(63deg, rgb(50 250 33 / 29%) 0%, rgb(50 250 33) 45%, rgb(50 250 33 / 39%) 87%)
}

/* search */
.search-section {
    margin-top: 60px; 
    padding-top: 2em;
    border-top: 1px solid #85858587;
    min-height: 640px;
    .search-column {
        .col-12 {
            margin-bottom: 1em;
        }
    }
    .search-result {
        font-size: var(--font-large);
        margin: 0 0 1em;
    }
    .box-item {
        background:linear-gradient(113deg, rgba(97, 97, 97, 0.41) 15%, rgb(73 73 73 / 34%) 75%, rgba(0, 0, 0, 0.17) 100%);
        border-radius: 20px;
        padding-top: 0;
        padding: 1em;
        .nft-image {
            width: 100%;
            height: auto;
        }
        .nft-name {
            padding: 0;
            height: auto;
            font-size: var(--font-medium-x);
        }
        .favorite {
            text-align: center;
            margin: 0 .5em;
            img {
                display: block;
                margin: 0 auto;
                text-align: center;
                max-width: 26px;
            }
        }
    }
    .box-filter {
        border: 1px solid #85858587;
        margin-right: 2em;
        border-radius: 12px;
        .filter {
            padding: .75em;
        }
        hr {
            margin: .25em 0;
        }
        .form-check {
            color:var(--secondary-text-color);
        }
    }
}
.content-section {
    margin-top: 60px;
    padding-top: 60px;
    min-height: 650px;
    font-size: var(--font-medium-x);
    border-top: 1px solid rgba(133, 133, 133, 0.5294117647);
}

.youtube-box {
    iframe, video  {
        border-radius: 24px;
    }
}
.ytp-fit-cover-video .html5-main-video, video {
    border-radius: 12px; 
}
.countdown{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 2em;
    h2 {
        font-size: 3.5em;
        text-shadow: 0 0 13px #e31f26;
    }
  }
  
  .all-times-bar{
    display: flex;
    margin: 2em 0;
    .timebox{
      display: block;
      margin-right: 20px;
      font-size: 2rem;
      &.last {
        margin-right: 0;
      }
      .title-tex{
        font-weight: 100;
      }
    }
  }
  .title-text {
    font-size: 3rem;
    color:#fff;
    font-family: 'impact', Helvetica, sans-serif;

  }
  .text {
    font-size: 1.2rem;
    text-align: center;
    font-weight: 200;
    text-transform: uppercase;
  }
  time{
    font-weight: 200;
  }
  svg {
    path {
      &:nth-child(2) {
        stroke-width: 2.7px;
  
      }
    }
  }
._banner {
    padding: 1em;
    margin-top: 2em;
}
.count-down-box {
    display: flex;
    justify-content: center;
    font-size: 1.5em;
    ._count {
        padding: 0 .5em 0 0;
        text-align: center;
        font-size: 2rem;
        .box {
            width: 48px;
            height: 48px;
            border: #fff solid 1px;
            border-radius: 50%;
            display: block;
            line-height: 44px;
        }
    }
    .text {
        display: block;
        font-size: 1rem;
    }

}

@media (max-width: 576px) { 
    .home-section {
        background-size: 100%;
    }
    .home-section .nft-tcc-discover h2 {
        font-size: var(--font-large);
    }
    .home-section .info-nft-header {
        display: none;
    }
    .content-inner .sub-content {
        padding: 0;
    }
    .content-inner .how-to-content .card-box {
        margin-bottom: 3em;
    }
    .content-inner .box-partner {
        max-width: 100%;
        border-radius: 20px;
    }
    .home-content .btn-nft {
        min-width: -webkit-fill-available;
    }
    .youtube-box {
        width: 100%;
    }
    .youtube-box iframe {
        width: 100%;
        height: 195px;
    }
    .title-text {
        font-size: 2rem;
    }
    .home-section .home-banner { 
        height: 342px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: 0px 140px;
    }
    .home-section .home-coutdown {
        background-color: #000;
    }
    .countdown h2 {
        font-size: 2em;
    }
    .home-section .nft-tcc-discover {
        padding-top: 2em;
    }
    .home-section .nft-tcc-discover .btn-discover {
        padding-left: 1em;
        padding-right: 1em;
        height: 40px;
        font-size: 1.2rem;
    }
    .countdown {
        padding-top: 0;
    }
    .all-times-bar {
        margin: 0;
    }
 }

//  iPhone 13 Pro Max
 @media only screen 
    and (device-width: 428px) 
    and (device-height: 926px) 
    and (-webkit-device-pixel-ratio: 3) { 

        .box-hash-confirm .hash-address {
            width: 200px;
        }
        .btn-go-nft  {
            width: 300px;
        }
        .wait-text {
            padding-right: 25px;
        }
    }