@import '../src/assets/sass/variables';
@import '../src/assets/sass/fonts';

body {
    background-color: var(--backgroud-primary-color);
    font-family: 'FC Friday', Helvetica, sans-serif!important;
    font-size: var(--font-medium);
    color:var(--primary-text-color);
    font-weight: normal;
    line-height: 1.2;
}
img {
    width: 100%;
}

ul {
    list-style-type: none;
}
h2, h4, h5 {
    text-transform: uppercase;
}
.cursor-pointer {
    cursor: pointer;
}
.hash-profile {
    border: 1px solid var(--alternative-text-color);
    color: var(--primary-text-color);
    border-radius: 20px;
    padding: .1em .5em;
    display: inline-block;
    cursor: pointer;
    font-size: 1.4em;
    line-height: 1.4;
    svg {
        font-size: .78em;
    }
}

/* page not found */
.page-not-found {
    margin: 5em auto;
    padding-top: 60px;
    min-height: 450px;
    text-align: center;
    .not-found {
        font-size: 12em;
        color: var(--red-color);
    }
    a {
        padding: .5em;
        margin-top: 3em;
        display: inline-block;
    }
    p {
        font-size: var(--font-large);
    }
    h2 {
        font-size: var(--font-large-xl);
    }
}

@media (max-width: 576px) {

    .hash-profile {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
}