@import 'fonts';
$assetPath : "/assets/images/button-claim.svg";

.bg {
    max-height: 530px;
    padding: 0px 10px 10px 10px;
    margin:1em;
    border-radius: 50px;
    box-shadow:0px 4px 45px #e31f2687;
    background-color:#282828;

    .card-body {
      padding: 2em 1em;
      ._icon-verify {
        width: 20px;
        height: 20px;
        vertical-align: baseline;
      }
    }
  }
  .carousel-indicators [data-bs-target] {
    border-radius: 50%;
    height: 12px;
    width: 12px;
    margin-right: 10px;
  }
  .carousel-indicators {
    bottom: -45px;
  }
    
._reccommeded-collection { 
  margin-top: 1em;
  h2 {
    font-size:2.5em;
    text-shadow: 0 0 13px #e31f26;
  }
}
.circle {
    border-radius: 100%;
    max-width: 113px;
}
.logo-radius{
    border-radius: 25px;
    margin: 0 auto;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    max-height: 420px;
}
.box-logo-collection {
  align-self: center;
  padding:1em 1.5em .5em 1em;
}
.title{
    flex-grow: 0;
    margin: 18px 18px 0px 0px;
    font-size: var(--font-large-x);
    text-align: center;
    color: #fff;
    text-transform: uppercase;
  }

  .title-owner {
    flex-grow: 0;
    margin: 7px 0px 14px 0px;
    font-size: var(--font-large);
    font-weight: normal;
    text-align: center;
    color: #33fb21;
  }

  .desc-collection {
    flex-grow: 0;
    margin: 0 0 20px;
    font-size: var(--font-medium-x);
    text-align: center;
    color: #fff;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1.2; /* fallback */
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .card-claim {
    padding:1em ;
    border-radius: 32px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-image:radial-gradient(125.38% 125.38% at 52.87% 50%, rgba(255, 255, 255, 0) 38.76%, #FFFFFF 76.29%);
    background-repeat: no-repeat;
    background-size: 114%;
    background-position: -6px 0px;
    // background-image: radial-gradient(circle at 50% 50%, rgb(255 255 255 / 24%) 16%, rgba(102, 102, 102, 0.2) 100%), linear-gradient(to bottom, rgba(102, 102, 102, 0.2), rgb(49 35 35 / 20%));
  }

  .button-claim {
    width: 200px;
    flex-grow: 0;
    margin: 6px 0 6px 72.9px;
    object-fit: contain;
    border-radius: 100px;
    box-shadow: 0 0 25px 0 #e31f26;
    background-image: radial-gradient(circle at 49% 50%, #e31f26 32%, #000 59%), linear-gradient(to bottom, rgba(227, 31, 38, 0.7), rgba(227, 31, 38, 0.7));
  }

  .button-claim-title {
    font-size: var(--font-large);
    color: #fff;
  }

  .button-claim-image {
        // background-image: url(#{$assetPath});
        // background-position: center; /* Center the image */
        // background-repeat: no-repeat; /* Do not repeat the image */
        // background-size: cover;     
        background:linear-gradient(63deg, rgb(7 7 7 / 59%) 0%, rgb(227, 31, 38) 45%, rgb(7 7 7 / 59%) 87%);
        box-shadow: 0px 0px 25px #E31F26;
        border-radius: 50px;
        justify-content: center;
        padding: 1em;
        cursor: pointer;
        font-size: 1.2em;
  }
  .logo-collection img {
    width: 112px;
    height: 112px;
    border-radius: 50%;
  }
  .airdrop-title {
    margin: 0 6px 10px 6px;
    font-size: 2em;
    line-height: 2;
    text-align: center;
    color: #fff;
  }

  @media (max-width: 576px) { 

    .bg {
      max-height: fit-content;
      border-radius: 8px;
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      .card-body {
        padding: 0;
      }
    }
    .logo-collection img { 
      width: 80px;
      height: 80px;
    }
    ._box-airdrop-nft {
      padding-left: 0;
      padding-right: 0;
    }
    .card-claim {
      padding: .5em 1em;
    }
    .airdrop-title {
      font-size: 1.5em;
      line-height: 2.5;
    }
    .button-claim-image {
      margin: 0;
      height: auto;
    }
  }