@import 'variables';
@import 'fonts';

a, a:visited, a:active , a:focus {
    color:var(--primary-text-color);
    text-decoration: none;
}
a:hover {
    color:inherit;
}
.nav-overlay-box {
    background-color: #000;
}
 .navbar {
     background-color: var( --backgroud-primary-color);
     padding:0;
     height: 72px;
     
     .navbar-toggler-icon {
        background-image:url('../images/icon-bar.svg')!important;
        width: 1em;
     }
     .user-profile {
         display: none;
     }
     .user-wallet {
        display: inline-block;
        line-height: 1.5;
        font-size: 1.2rem;
        line-height: 60px;
     }
 }
 .nav-profile {
    .dropdown-toggle::after {
        display: none;
        border:none;
    }
    .nav-link {
        padding: 0!important;
        border-bottom: none!important;
        i {
            vertical-align: middle;
        }
        &:hover , &:active, &:focus{
            color: #fff;
        }
    }
 }
 .dropdown-menu {
    a, a:visited, a:active, a:focus {
        color: var(--black-color);
        text-transform: uppercase;
    }
}
 .dropdown-menu .dropdown-item {
      svg {
         font-size: var(--font-large-x);
         padding-right: .5em;
     }
 }
 .navbar-nav {
    align-items: center;
 }
 ._search-d {
     width: 40%;
     position: relative;
     margin-top: 5px;
     input[type="search"] {
         border-radius: 24px;
         background-color: var(--background-secondary-color);
         color:var(--primary-text-color);
         border: none;
         padding: .2em .75em;
         padding-left: 2.5em;
     }
 }
 ._icon {
    display:inline-block;
    width:24px;
    height:24px;
    background-repeat:no-repeat;
    background-size:100%;
    margin-right: 16px;
    margin-left: 16px;
    vertical-align: middle;
    &-metamask {
        background-image:url('../images/icon-metamask.svg');
        margin-right: 10px;
    }
    &-small-check {
        background-image:url('../images/icon-small-check.svg');
        width: 8px;
        height: 8px;
        vertical-align: middle;
    }
    &-polygon-b {
        background-image:url('../images/icon-polygon-b.svg');
    }
    &-user {
        background-image:url('../images/icon-user.svg');
    }
    &-wallet {
        background-image:url('../images/icon-wallet.svg');
        margin-right: 6px;
    }
    &-wallet-active {
        background-image:url('../images/icon-wallet-active.svg');
        margin-right: 6px;
    }
    &-subtract {
        background-image:url('../images/icon-subtract.svg');
    }
    &-tcc-io {
        background-image:url('../images/icon-tcc-io.png');
        width: 37px;
        height: 37px;
        vertical-align: bottom;
        margin-right: 6px;
    }
    &-search {
        background-image:url('../images/icon-search.svg');
        position: absolute;
        left: -6px;
        top: 3px;
    }
    &-contact {
        background-image:url('../images/icon-contact.svg');
        margin-left: 0;
        margin-right: 5px;
    }
    &-web-io {
        background-image:url('../images/icon-tccio.svg');
        margin-left: 0;
        margin-right: 5px;
    }
    &-verify {
        background-image: url('../images/icon-verify.svg');
        vertical-align: middle;
        width: 24px;
        height: 25px;
        margin-left: 0;
    }
 }
 .modal-private-code._wallet .modal-content{
    border-top-left-radius:100px;
    border-bottom-left-radius:100px;
    ._icon-close  {
        position: absolute;
        right: 5px;
    }
    .modal-footer {
        justify-content: center;
        border-top: none;
        .btn-logout {
            background-color: transparent;
            box-shadow: 0px 0px 25px #e31f26;
            background: rgb(227, 31, 38);
            background: linear-gradient(63deg, rgba(227, 31, 38, 0.2931766457) 0%, rgb(227, 31, 38) 45%, rgba(227, 31, 38, 0.3940169818) 87%);
            border-color: transparent;
            color: var(--white-color);
            border-radius: 50px;
            border-bottom: var(--red-color) 1px solid;
            border-color: var(--red-color);
            padding-left: 1.5em;
            padding-right: 1.5em;
        }
    }
 }
 .list-wallet-menu {
    text-transform: uppercase;
    .token {
        margin-right: 10px;
        width: 20px;
    }
    .header {
        font-size: var(--font-large);
    }
    .show > .btn-secondary.dropdown-toggle {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
     }
    .dropdown {
     border-bottom: none; 
     padding: 0;
     button {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
        line-height: 0;
     }
     div {
        border-bottom: none;
        padding: .25em 0;
     }
    }
    div {
        border-bottom:#444444 1px solid;
        padding: 1em 0;
        i {
            margin-left: 0;
        }
    }
 }
 .box-address {
    margin-top: 1.5em;
    h2 {
        display: none;
    }
    .address {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow:  0px 4px 4px rgba(0, 0, 0, 0.25);
        cursor: pointer;
    }
    .card-box-my-wallet-balance {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow:  0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        padding: 1em;
        border:solid 1px var(--secondary-text-color);
    }
 }
 .nft-brand {
    color: var(--primary-text-color);
    vertical-align: text-bottom;
    font-size: 1.5em;
 }
 a._discover.active {
    background: linear-gradient(144deg, #000 12%, #f00 150%);
    color: var(--red-color)!important;
 }
.discover {
    margin-right: 16px;
    margin-left: 24px;
    line-height: 60px;
    font-size: 1.2rem;
    &.active { 
        // background: linear-gradient(144deg, #000 12%, #f00 150%);
        padding-left: 6px;
        padding-right: 6px;
        a { 
            color:var(--red-color)!important;
        }
    }
    svg {
        display: none;
    }
}
.nav-top-profile ._icon-user {
    border-radius: 50%;
}
.nav-top-profile.active {
    background: linear-gradient(144deg, #000 12%, #f00 150%);
    // box-shadow: 0 0 12px 0 #e31f26;
    display: block;
    line-height: 60px;
    ._icon-user {
        background-image: url('../images/icon-user-active.svg');
    }

}
.box-alert-title {
    z-index: 1080;
    bottom: 0;
    text-align: center;
    position: fixed;
    width: 100%;
    margin: 0 auto;
    font-size: .76em;
    border-radius: 0;
    background: #2f8a06;
    color: #fff;
    border-color: transparent;
    padding: 10px 0;
    .alert-title {
        font-size: var(--font-large);
        text-transform: uppercase;
    }
}
._network-available {
    img {
        margin-right: 10px;
    }
    a {
        padding: 0.57rem 1rem;
    }
}
.process-modal {
    position: absolute;
    right: 10px;
    bottom: -40px;
    background: #fff;
    border-radius: 8px;
    color: #000;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    &.hide {
        right:-100%
    }
    .process-text {
        padding: 0 .7rem;
    }
   .icon-waiting {
        max-width: 18px;
    }
}

@media (min-width: 992px) { 
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        left: -90px;
    }

}
 
@media (max-width: 576px) { 
    ._search-d , .navbar-collapse, .dropdown {
        width: 100%; 
    }
    .navbar {
        border-bottom: 1px solid var( --background-secondary-color);
        .user-profile, .user-wallet {
            display: inline-block;
            color: var(--primary-text-color);
            font-size: var(--font-medium)!important;
            vertical-align: middle;
                }
    }
    .navbar-nav {
        padding: .125em 0.75em 0 .25em;
        align-items: baseline;

        ._icon {
            margin-right: 10px;
        }
        .discover {
            margin-left: 3px;
            margin-bottom: .5em;
            svg {
                font-size: 20px;
                margin-right: 8px;
                display: inline-block;
            }
        }
        a {
            margin-bottom: .5em;
            // color:var(--primary-text-color);
            text-decoration: none;
        }
    }
    ._icon-search {
        left: 5px;
    }
    ._search-d {
        margin-bottom: 1em;
        margin-top: 1em;
        padding-left: 1em;
    }
}

.address {
    width: 100%;
    margin-bottom:1em;
    padding: 5.5px 27.4px 5.5px 18.7px;
    object-fit: contain;
    border-radius: 50px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border: solid 1px var(--secondary-text-color);
    position: relative;
  }
  .address-text {
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    margin: 0 9.9px 0 0;
    font-size: var(--font-medium);
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .token {
    float: none;
    margin: auto;
    width: 18px;
    justify-content: center;
    
    .circle {
        border-radius: 100%;
        margin-right: 10px;
    }
  }
  .ETH {
    width: 45px;
    font-weight: bold;
    text-align: left;
    color: #fff;
  }
  .Ethereum {
    width: 97px;
    letter-spacing: normal;
    text-align: left;
    color: #626262;
    line-height: 1;
  }

  .currency {
    font-weight: bold;
    text-align: right;
    color: #fff;
  }

  .currency-usd {
    letter-spacing: normal;
    text-align: right;
    color: #626262;
  }

  .currency-total {
    height: 34px;
    flex-grow: 0;
    margin: 19.3px 0 0;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.85;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  .currency-box{
    padding: 1.2em;
    border-radius: 30px;
    background: rgba(71,71,71,1);
    background: -moz-linear-gradient(45deg, rgba(71,71,71,1) 0%, rgba(7,8,7,1) 100%);
    background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(71,71,71,1)), color-stop(100%, rgba(7,8,7,1)));
    background: -webkit-linear-gradient(45deg, rgba(71,71,71,1) 0%, rgba(7,8,7,1) 100%);
    background: -o-linear-gradient(45deg, rgba(71,71,71,1) 0%, rgba(7,8,7,1) 100%);
    background: -ms-linear-gradient(45deg, rgba(71,71,71,1) 0%, rgba(7,8,7,1) 100%);
    background: linear-gradient(45deg, rgba(71,71,71,1) 0%, rgba(7,8,7,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#474747', endColorstr='#070807', GradientType=1 );
    font-size: var(--font-small);
    text-align: center;
    position: relative;
    color:var(--primary-text-color);
    font-size: var(--font-medium-x);
    text-transform: uppercase;
}

.text-modal {
    text-align: left;
    color: #696969;
    font-size: var(--font-medium-x);
}

.text-modal-title {
    font-weight: bold;
    text-align: left;
    color: #696969;
    justify-content: center;
}

.btn-optional {
    background-color: var(--primary-text-color);
    border:var(--footer-background-color) solid 1px;
    color:var(--footer-background-color);
    text-transform: uppercase;
    padding-left: 1em;
    padding-right: 1em;
    &:hover {
        background-color: var(--footer-background-color);
        color:var(--primary-text-color);
    }
}