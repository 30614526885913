.table {
    margin-top: 50px;
    margin-bottom: 50px;
}

.activity_avatar {
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }

.row-item{
    margin-top: 100px;
    padding-top: 50px;
}
.event-table.table > :not(:first-child) {
    border-top: none;
}
.event-table.table { 
    .event-icon svg {
        margin-right: 5px;
    }
    p {
        color: var( --alternative-text-color);
        padding-top: 5px;
        margin-bottom: 0;
    }
    & > :not(caption) > * > * {
        padding: 0.75rem 1rem;
    }
    & > :not(caption) > * > * {
        vertical-align: middle;
    }
    th:first-child {
        border-top-left-radius: 8px;
    }
    th:last-child {
        border-top-right-radius: 8px;
    }
    tr:last-child td:first-child {
        border-bottom-left-radius: 8px;
    }
    tr:last-child td:last-child {
        border-bottom-right-radius: 8px;
    }
}