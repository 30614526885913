 .modal-private-code {
    left: inherit;
    width: 450px;
    right: 0;
    .modal-dialog {
        padding-left: .5em;
    }
    .modal-content {
        height: 95vh;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
        background-color: var(--backgroud-primary-color);
        box-shadow: 0 -4px 15px 0 #e31f26;
        border: solid 1px #e31f26;
        border-right: none;
        padding: 1em;
        .currency-box {
            margin-bottom: .79em;
        }
        .box-currency-name {
            padding-left: 0!important;
        }
        ._copy {
            position: absolute;
            right: 10px;
            top:8px;
        }
    }
    .modal-title {
        font-size: var(--font-medium);
    }
    .modal-header {
        border-bottom: none;
    }
    .modal-body {
        // overflow: scroll;
    }
    .btn-close {
        color:var(--primary-text-color);
    }
    .box-private-code {
        padding: 1.2em;
        padding-top: 3em;
        border-radius: var(--s-radius);
        background: url('../images/box-gradient.png');
        background-size: auto;
        background-repeat: no-repeat;
        font-size: var(--font-small);
        text-align: center;
        position: relative;
        color:var(--primary-text-color);
        font-size: var(--font-medium-x);
        text-transform: uppercase;
    }
    .input-private-code {
        border-radius: 24px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        border: solid 1px var(--secondary-text-color);
        background-color: rgba(255, 255, 255, 0.2);
        color: var(--primary-text-color);
        font-size: var(--font-medium);
        text-align: center;
        text-transform: uppercase;
    }
    .btn-nft {
        padding-left: 2.2em;
        padding-right: 2.2em;
        box-shadow: 0 0 15px 0 #e31f26;
        font-size: var(--font-medium);
    }
    .captcha-box {
        padding-top: 1em;
        padding-bottom: 1em;
        display: inline-block;
        
    } 
 }
 

 .modal-alert {
    &._waiting-modal {
        .modal-content {
            padding: 1em;
            // background: radial-gradient(125.38% 125.38% at 52.87% 50%, rgba(255, 255, 255, 0) 38.76%, #FFFFFF 76.29%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, rgba(102, 102, 102, 0.2);
            // opacity: 0.5;
            // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            // border-radius: 50px;

            button {
                background: linear-gradient(63deg,rgba(227,31,38,.09),#e31f26 45%,rgba(227,31,38,.09) 87%);
                border: 1px solid #E31F26;
                box-shadow: 0px 0px 25px #E31F26;
                border-radius: 24px;
                min-width: 120px
            }
        }
    }
    .modal-content {
        border-radius: var(--s-radius);
        background: rgb(54,54,54);
        // background: linear-gradient(283deg, rgba(54,54,54,1) 15%, rgba(96,95,95,0.65172006302521) 75%, rgba(38,38,38,1) 100%);  
        background: linear-gradient(283deg, rgb(54, 54, 54) 15%, rgb(46 44 44 / 91%) 75%, rgb(38, 38, 38) 100%);      
        background-size: contain;
        background-repeat: no-repeat;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        padding: 2em;
    }
    .icon-success, .icon-error {
        width: 60px;
    }
    .icon-waiting {
        width: 48px;
    }
    button {
        padding-left: 2.2em;
        padding-right: 2.2em;
    }

    ._icon-share-footer {
        display: inline-block;
        width: 50px;
        height: 50px;
        background-repeat: no-repeat;
        background-size: 100%;
        margin-right: 8px;
    
    &-facebook {
        background-image: url('../images/icon-facebook.svg');
    }
    &-twitter {
        background-image: url('../images/icon-twitter-2.svg');
    }
    &-telegram {
        background-image: url('../images/icon-telegram-app.svg');
    }
    
    
    }

    .close {
        color: #fff; 
        opacity: 1;
    }
 }
 ._icon-close {
     background-image: url('../images/icon-close.svg');
 }

 
 @media (max-width: 576px) { 

    .modal-alert button {
        padding-left: 0;
        padding-right: 0;
    }
     
    .modal-private-code {
        width: 100%;
        ._icon {
            margin-right: 0;
        }
    }
    .modal-private-code .modal-body {
        padding: 0;
    }
 }

 .rotate {
    animation: loading 3s linear infinite;
    @keyframes loading {
     0% { 
       transform: rotate(0); 
     }
     100% { 
       transform: rotate(360deg);
     }
    }
   }